import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { saveNewClient } from "../../application/client";
import { useState } from "react";

function NewClient() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const newClientCompany = watch("newClientCompany");

  const onSubmit = (data) => {
    saveNewClient(data);
    alert("Cliente creado con éxito");
    reset();
    setIsSubmitted(true);
  };

  return (
    <section className="w-8/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
      <h1 className="text-xl font-bold text-black capitalize dark:text-white">
        Nuevo Cliente
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mt-4 mb-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">
              Datos Personales
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="newClientCompany"
            >
              Nuevo Cliente
            </label>
            <input
              {...register("newClientCompany")}
              type="radio"
              value="cliente"
              required={true}
            />
          </div>

          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="newClientCompany"
            >
              Nueva Empresa
            </label>
            <input
              {...register("newClientCompany")}
              type="radio"
              value="empresa"
              required={true}
            />
          </div>

          {newClientCompany === "cliente" && (
            <>
              <FormField
                label="Nombre"
                id="name"
                name="nombre"
                type="text"
                required={true}
                maxLength={40}
                placeholder="Nombre"
                autoComplete={"off"}
                errors={errors}
                register={register}
                //validation={NAME}
              />

              <FormField
                label="Apellido"
                id="lastName"
                name="apellido"
                type="text"
                required={true}
                maxLength={40}
                placeholder="Apellido"
                autoComplete={"off"}
                errors={errors}
                register={register}
                //validation={NAME}
              />
            </>
          )}
          {newClientCompany === "empresa" && (
            <FormField
              label="Nombre de la Empresa"
              id="nameCompany"
              name="nombre de la empresa"
              type="text"
              required={true}
              placeholder="Nombre de la Empresa"
              autoComplete={"off"}
              errors={errors}
              register={register}
              //validation={NAME}
            />
          )}

          <FormField
            label="Seleccionar tipo"
            id="TaxIdentification"
            required={true}
            name="seleccionar cuil o cuit"
            type="select"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar Cuit / Cuil / Dni" },
              { value: "dni", label: "Dni" },
              { value: "cuil", label: "Cuil" },
              { value: "cuit", label: "Cuit" },
            ]}
          />

          <FormField
            label="Número de Cuil / Cuit / Dni"
            id="cuilCuitDni"
            name="Cuil / Cuit / Dni "
            required={true}
            type="text"
            maxLength={11}
            placeholder="Cuil / Cuit / Dni"
            autoComplete={"off"}
            errors={errors}
            register={register}
            // validation={DNI}
          />

          <FormField
            label="Seleccionar Iva"
            id="iva"
            required={true}
            name="seleccionar iva"
            type="select"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar iva" },
              { value: "ri", label: "Responsable Inscripto" },
              { value: "cf", label: "Consumidor Final" },
              { value: "e", label: "Excento" },
            ]}
          />
          <FormField
            label="Razón Social"
            id="billingName"
            name="razon social"
            type="text"
            required={false}
            placeholder="Razon Social"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Domicilio</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Localidad"
            id="location"
            name="localidad"
            required={true}
            type="text"
            maxLength={35}
            placeholder="Localidad"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="Dirección"
            id="direction"
            name="dirección"
            required={true}
            type="text"
            maxLength={35}
            placeholder="Dirección"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />
        </div>

        <div className="relative mt-10">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Contacto</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Teléfono"
            id="phone"
            name="telefono"
            required={true}
            type="number"
            maxLength={10}
            placeholder="Telefono"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={PHONE}
            warning="Telefono sin el 0 y sin el 15."
          />

          <FormField
            label="Email"
            id="email"
            name="correo electronico"
            type="email"
            required={false}
            maxLength={35}
            placeholder="Correo Electronico"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={EMAIL}
            warning="hola@dominio.com"
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
          >
            Guardar
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewClient;
