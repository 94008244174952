import firebase, { db } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
} from "firebase/firestore";

// Save new cash movement
export const saveNewCash = async (data) => {
  await addDoc(collection(db, "cash"), data);
};

// Get all cash movements
export const getAllCash = async () => {
  const querySnapshot = await getDocs(collection(db, "cash"));
  const cash = [];
  querySnapshot.forEach((doc) => {
    cash.push({ ...doc.data(), id: doc.id });
  });
  return cash;
};
