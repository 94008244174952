import { useState } from "react";
import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";

import { FaTrashAlt } from "react-icons/fa";
import { BsCloudUpload } from "react-icons/bs";

function NewProduct() {
  const [selectImages, setSelectImages] = useState([]);
  console.log(selectImages);
  const onSelectFile = (e) => {
    const selectFiles = e.target.files;
    const selectFilesArray = Array.from(selectFiles);

    const imagesArray = selectFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectImages((previousImages) => previousImages.concat(imagesArray));
  };

  console.log(selectImages);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <section className="w-8/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
      <h1 className="text-xl font-bold text-black capitalize dark:text-white">
        Nuevo Producto
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mt-4 mb-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Imagenes</span>
          </div>
        </div>

        <div className="w-full mx-auto">
          {selectImages.length >= 0 && selectImages.length < 4 && (
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-white dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <BsCloudUpload className="w-8 h-8 text-orange-400" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Haga clic para cargar</span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG
                  </p>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={onSelectFile}
                  accept="image/*"
                />
              </label>
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-center items-center gap-4 mt-2">
          {selectImages &&
            selectImages.map((image, index) => {
              return (
                <div
                  className="cursor-pointer h-44 mobile:mb-8 table:mb-4 relative w-44"
                  key={index}
                  onClick={() =>
                    setSelectImages(selectImages.filter((e) => e !== image))
                  }
                >
                  <div
                    className="absolute bg-center bg-cover border inset-0 rounded-2xl"
                    style={{ backgroundImage: `url(${image})` }}
                  ></div>
                  <div className="absolute border duration-300 flex hover:opacity-100 inset-0 items-center justify-center opacity-0 rounded-2xl text-5xl">
                    <FaTrashAlt className="text-white" />
                  </div>
                </div>
              );
            })}
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">
              Datos del producto
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Nombre del producto"
            id="name"
            name="nombre"
            type="text"
            required={true}
            maxLength={20}
            placeholder="Nombre del producto"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Código"
            id="code"
            name="código"
            required={true}
            type="text"
            maxLength={12}
            placeholder="Código"
            autoComplete={"off"}
            errors={errors}
            register={register}
            // validation={DNI}
          />
          <FormField
            label="Proveedor / Marca"
            id="providerBrand"
            required={true}
            name="proveedor / marca"
            type="select"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar Proveedor / Marca" },
              { value: "cuil", label: "Cuil" },
              { value: "cuit", label: "Cuit" },
            ]}
          />

          <FormField
            label="Cantidad"
            id="quantity"
            name="cantidad"
            type="number"
            required={true}
            maxLength={4}
            placeholder="Cantidad"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#EE761B] hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
          >
            Guardar
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewProduct;
