import React, { useEffect, useMemo, useState } from "react";
import { getAllProviders } from "../../application/providers";
import TableContainer from "../../components/TableContainer";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";

function TableProviders() {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const getProviders = async () => {
      const response = await getAllProviders();
      setProviders(response);
      setIsLoading(false);
    };
    getProviders();
  }, []);

  const title = "Lista de Proveedores";

  const columns = useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "name",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Teléfono",
        accessor: "phone",
      },
      {
        Header: "CUIT",
        accessor: "cuitNumber",
      },
      {
        Header: "Banco",
        accessor: "bankName",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "CBU",
        accessor: "cbu",
      },
      {
        Header: "Dolar",
        accessor: "dollarPrice",
      },
      {
        Header: "Localidad",
        accessor: "location",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Dirección",
        accessor: "direction",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  if (isLoading) {
    return <Loading />;
  }

  if (providers.length === 0) {
    return (
      <div className="h-full">
        <div className="container mx-auto">
          <div className="text-center p-8">
            <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
              {title}
            </h1>
          </div>
          <div className="pl-2 pr-2">
            <AlertsMenssages
              className
              type="warning"
              message="No hay registros"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
            {title}
          </h1>
        </div>
        <TableContainer columns={columns} data={providers} />
      </div>
    </div>
  );
}

export default TableProviders;
