import React from "react";

function ActionCard(props) {
  const { title, description, icon, onClick } = props;

  return (
    <button className="m-4" onClick={onClick}>
      <div className="shadow-lg rounded-2xl bg-white dark:bg-gray-700 w-64 h-64">
        <div className="w-full h-full text-center flex items-center justify-center">
          <div className="flex flex-col">
            <div className="bg-primary w-20 h-20 rounded-full flex items-center justify-center m-auto">
              <div className="text-5xl text-white dark:text-black">{icon}</div>
            </div>
            <p className="text-gray-800 dark:text-gray-200 text-2xl font-bold mt-4">
              {title}
            </p>
            <p className="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">
              {description}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}

export default ActionCard;
