import { set, useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";
import { getSearchClients } from "../../application/client";
import {
  saveNewRepair,
  getHighestRepairNumber,
} from "../../application/repair";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import PDFNewRepair from "../../components/PDFGenerator/PDFNewRepair";

function NewRepair() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [searchResults, setSearchResults] = useState([]);
  const [dataRepair, setDataRepair] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repairNumber, setRepairNumber] = useState(null);

  useEffect(() => {
    const fetchHighestRepairNumber = async () => {
      const highestNumber = await getHighestRepairNumber();
      const formattedNumber = (parseInt(highestNumber) + 1)
        .toString()
        .padStart(6, "0");
      setRepairNumber(formattedNumber);
    };

    fetchHighestRepairNumber();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const results = await getSearchClients(data);
    setSearchResults(results);
    setIsLoading(false);
    setHasSearched(true);
    setIsFormSubmitted(false);
  };

  const handleSelectClient = (client) => {
    setSelectedClient(client);
  };

  const handleCancelSelection = () => {
    setSelectedClient(null);
  };

  const onSubmitNewRepair = async (data) => {
    setLoading(true);
    setDataRepair(data);
    const datos = {
      ...data,
      clientId: selectedClient.id,
      deadline: "",
      repairDate: "",
      observations: "",
      repairNotes: "",
      repairStatus: "pendiente",
    };
    const results = await saveNewRepair(datos);
    setIsFormSubmitted(true);
    setLoading(false);
  };

  return (
    <>
      <div className="min-h-screenflex justify-center items-center">
        <div className="container mx-auto bg-orange-500 rounded-lg p-14">
          <h1 className="text-center font-bold text-white text-4xl mb-8">
            Buscar cliente
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sm:flex items-center bg-white rounded-lg overflow-hidden px-2 py-1 justify-between">
              <div className="w-full">
                <FormField
                  id="name"
                  name="buscar cliente"
                  type="text"
                  required={true}
                  maxLength={20}
                  placeholder="Busca un cliente por su nombre, apellido, cuit o cuil"
                  autoComplete={"off"}
                  errors={errors}
                  register={register}
                />
              </div>

              <div className="ms:flex items-center px-2 rounded-lg space-x-4 mx-auto">
                <button
                  type="submit"
                  className="bg-orange-500 text-white text-base rounded-lg px-4 py-2"
                >
                  Realizar búsqueda
                </button>
              </div>
            </div>
          </form>
          {isLoading && (
            <div className="mt-6">
              <Loading />
            </div>
          )}
          {!isLoading &&
            hasSearched &&
            searchResults.length > 0 &&
            !selectedClient && (
              <div className="mt-4">
                <ul>
                  {searchResults.map((client) => (
                    <li
                      key={client.id}
                      onClick={() => handleSelectClient(client)}
                    >
                      <div className="w-full flex p-3 pl-4 items-center hover:bg-gray-300 rounded-lg cursor-pointer border mt-2 bg-white">
                        <div className="mr-4">
                          <div className="h-9 w-9 rounded-sm flex items-center justify-center text-3xl">
                            <FaUser />
                          </div>
                        </div>
                        <div>
                          <div className="font-bold text-lg capitalize">
                            {client.name} {client.lastName} {client.nameCompany}
                          </div>
                          <div className="text-xs text-gray-500 capitalize">
                            <span className="mr-2">
                              {client.TaxIdentification}: {client.cuilCuitDni}
                            </span>
                            <span className="mr-2">
                              Télefono: {client.phone}
                            </span>
                            <span className="mr-2">Email: {client.email}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          {!isLoading && hasSearched && searchResults.length === 0 && (
            <>
              <Link to={"/dashboard/clientes/agregar"}>
                <div className="w-full flex p-3 pl-4 items-center hover:bg-gray-300 rounded-lg cursor-pointer border mt-2 bg-white">
                  <div className="mr-4">
                    <div className="h-9 w-9 rounded-sm flex items-center justify-center text-3xl">
                      <FaUser />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-lg capitalize">
                      Agregar nuevo cliente
                    </div>
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <AlertsMenssages
                  type={"danger"}
                  message={"No se encontraron resultados"}
                />
              </div>
            </>
          )}
        </div>
        {selectedClient && isFormSubmitted === false && (
          <div className="mt-4 container mx-auto">
            <section className="w-10/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
              <h1 className="text-xl font-bold text-black capitalize dark:text-white">
                Nueva Reparación
              </h1>
              <form onSubmit={handleSubmit(onSubmitNewRepair)}>
                <div className="relative mt-4 mb-4">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-gray-500 bg-white">
                      Datos de la maquina
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <FormField
                    label="Marca"
                    id="brand"
                    name="marca"
                    type="text"
                    required={true}
                    maxLength={20}
                    placeholder="Marca"
                    autoComplete={"off"}
                    errors={errors}
                    register={register}
                    //validation={NAME}
                  />

                  <FormField
                    label="Modelo"
                    id="model"
                    name="modelo"
                    required={true}
                    type="text"
                    maxLength={20}
                    placeholder="Modelo"
                    autoComplete={"off"}
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="Fecha de ingreso"
                    id="dateAdmission"
                    name="fecha de ingreso"
                    type="date"
                    required={true}
                    errors={errors}
                    register={register}
                    //validation={NAME}
                  />
                </div>

                <div className="relative mt-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-gray-500 bg-white">
                      Caracteristicas
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <FormField
                    label="No enciende"
                    id="notStart"
                    name="no enciende"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="No tiene fuerza"
                    id="notForce"
                    name="no tiene fuerza"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="Arranca y se para"
                    id="startAndStop"
                    name="arranca y se para"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="Afilación"
                    id="sharpening"
                    name="afilacion"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="Carburación"
                    id="carburation"
                    name="carburacion"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                  <FormField
                    label="Service"
                    id="service"
                    name="service"
                    type="checkbox"
                    errors={errors}
                    register={register}
                    // validation={DNI}
                  />
                </div>

                <div className="relative mt-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-gray-500 bg-white">Notas</span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6 mt-4">
                  <FormField
                    id="description"
                    name="description"
                    type="textarea"
                    rows={5}
                    placeholder="Descripcion de la reparación"
                    autoComplete={"off"}
                    errors={errors}
                    register={register}
                    //validation={DOMICILIO}
                  />
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <button
                    onClick={handleCancelSelection}
                    type="submit"
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-red-500 hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#EE761B] hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
                    disabled={loading} // Deshabilita el botón mientras isLoading sea true
                  >
                    {loading ? (
                      <svg
                        role="status"
                        className="inline h-8 w-8 animate-spin mr-2 text-gray-200 dark:text-gray-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Guardar"
                    )}
                  </button>
                </div>
              </form>
            </section>
          </div>
        )}
        {isFormSubmitted && (
          <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
            <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
              <div className="w-full">
                <div className="m-8 my-20 max-w-[400px] mx-auto">
                  <div className="mb-8">
                    <h1 className="mb-4 text-3xl font-extrabold">
                      Reparación cargada exitosamente
                    </h1>
                    <p className="text-gray-600">
                      Descargar el comprobante de la reparación
                    </p>
                  </div>
                  <div className="space-y-4">
                    <PDFNewRepair
                      dataClient={selectedClient}
                      dataRepair={dataRepair}
                      repairNumber={repairNumber}
                    />
                    <Link to={"/dashboard/reparaciones"}>
                      <button className="p-3 mt-2 bg-white border rounded-full w-full font-semibold">
                        Volver al inicio
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NewRepair;
