import React from "react";
import ActionCard from "../../components/ActionCard";
import { useNavigate } from "react-router-dom";

import { FaUserPlus, FaUser } from "react-icons/fa";

function Bank() {
  const navigate = useNavigate();

  const cards = [
    {
      description: "Agregar un nuevo cheque",
      icon: <FaUserPlus />,
      routePath: "/dashboard/banco/agregar",
      title: "Agregar cheque",
    },
    {
      description: "Ver todos los cheques",
      icon: <FaUser />,
      routePath: "/dashboard/banco/ver",
      title: "Ver cheques",
    },
  ];

  const handleOnClickCard = (routePath) => {
    navigate(routePath);
  };

  return (
    <div className="flex flex-wrap justify-center w-full sm:justify-start ">
      {cards.map((card, index) => (
        <ActionCard
          description={card.description}
          icon={card.icon}
          key={index}
          title={card.title}
          onClick={() => handleOnClickCard(card.routePath)}
        />
      ))}
    </div>
  );
}

export default Bank;
