import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { saveNewProvider } from "../../application/providers";
import { useState } from "react";

function NewProviders() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    saveNewProvider(data);
    alert("Proveedor creado con éxito");
    reset();
    setIsSubmitted(true);
  };
  return (
    <section className="w-8/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
      <h1 className="text-xl font-bold text-black capitalize dark:text-white">
        Nuevo Proveedor
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mt-4 mb-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">
              Datos del Proveedor
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Nombre del Proveedor / Marca"
            id="name"
            name="nombre"
            type="text"
            required={true}
            maxLength={20}
            placeholder="Nombre del Proveedor"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Número de Cuit"
            id="cuitNumber"
            name="número Cuit"
            required={true}
            type="text"
            maxLength={11}
            placeholder="Número de Cuit"
            autoComplete={"off"}
            errors={errors}
            register={register}
            // validation={DNI}
          />

          <FormField
            label="Página Web"
            id="webPage"
            name="página web"
            type="text"
            required={true}
            maxLength={40}
            placeholder="Página Web"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Dolar - Tipo de Cambio"
            id="dollarPrice"
            name="precio dolar"
            type="text"
            required={true}
            maxLength={6}
            placeholder="Dolar - Tipo de Cambio"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Banco</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Nombre del Banco"
            id="bankName"
            name="nombre del banco"
            required={true}
            type="text"
            maxLength={20}
            placeholder="Nombre del Banco"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="Número de Cuenta"
            id="accountNumber"
            name="número de cuenta"
            type="number"
            required={true}
            maxLength={16}
            placeholder="Número de Cuenta"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Alias"
            id="alias"
            name="alias"
            required={true}
            type="text"
            maxLength={20}
            placeholder="Alias"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="CBU"
            id="cbu"
            name="cbu"
            type="number"
            required={true}
            maxLength={22}
            placeholder="CBU"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Domicilio</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Localidad"
            id="location"
            name="localidad"
            required={true}
            type="text"
            maxLength={20}
            placeholder="Localidad"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="Dirección"
            id="direction"
            name="dirección"
            required={true}
            type="text"
            maxLength={20}
            placeholder="Dirección"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />
        </div>

        <div className="relative mt-10">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Contacto</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Teléfono"
            id="phone"
            name="telefono"
            required={true}
            type="number"
            maxLength={10}
            placeholder="Telefono"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={PHONE}
            warning="Telefono sin el 0 y sin el 15."
          />

          <FormField
            label="Email"
            id="email"
            name="correo electronico"
            type="email"
            required={true}
            maxLength={35}
            placeholder="Correo Electronico"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={EMAIL}
            warning="hola@dominio.com"
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
          >
            Guardar
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewProviders;
