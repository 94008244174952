import useDarkMode from "../hooks/useDarkMode";
import { FaMoon, FaSun } from "react-icons/fa";

function Navbar() {
  const [colorTheme, setTheme] = useDarkMode();
  return (
    <header className="w-full h-16 z-40 p-2">
      <div className="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
        <div className="relative p-1 flex items-center w-full space-x-4 justify-end">
          <button
            onClick={() => setTheme(colorTheme)}
            className="flex p-2 items-center rounded-full text-gray-400 hover:text-gray-700 bg-white shadow text-md"
          >
            {colorTheme === "light" ? <FaSun /> : <FaMoon />}
          </button>

          <span className="w-1 h-8 rounded-lg bg-gray-200"></span>
          <a href="#s" className="block relative">
            <div className="flex items-center justify-center mx-auto object-cover rounded-full h-10 w-10 bg-primary">
              <span className="text-white text-xl uppercase">EM</span>
            </div>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
