import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#F5F5F5",
    padding: 40,
    borderWidth: 2,
    borderColor: "#333333",
    fontFamily: "Helvetica",
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    textTransform: "capitalize",
  },
  footer: {
    bottom: 0,
    fontSize: 10,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "#333333",
    borderBottomStyle: "solid",
    marginTop: 10,
    marginBottom: 10,
  },
  column: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

function PDFNewRepair(data) {
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src="/logo.png" style={styles.logo} />
        <Text style={styles.title}>Comprobante de Reparación</Text>
        <View style={styles.column}>
          <View>
            <Text style={styles.subtitle}>Información del cliente</Text>
            <Text style={styles.text}>Nombre: {data.dataClient.name} </Text>
            <Text style={styles.text}>
              Apellido: {data.dataClient.lastName}{" "}
            </Text>
            <Text style={styles.text}>
              Dirección: {data.dataClient.direction}
            </Text>
            <Text style={styles.text}>Ciudad: {data.dataClient.location}</Text>
          </View>
          <View>
            <Text style={styles.subtitle}>Información del local</Text>
            <Text style={styles.text}>Dirección: Franklin 20</Text>
            <Text style={styles.text}>Teléfono: 2241680864</Text>
            <Text style={styles.text}>Ciudad: {data.dataClient.location}</Text>
          </View>
        </View>
        <View style={styles.line} />
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <Text style={styles.subtitle}>Detalles de la maquina</Text>
          <Text style={styles.text}>Marca: {data.dataRepair.brand} </Text>
          <Text style={styles.text}>Modelo: {data.dataRepair.model} </Text>
          <Text style={styles.text}>
            Fecha de ingreso:
            {new Date(data.dataRepair.dateAdmission).toLocaleDateString(
              "es-ES"
            )}
          </Text>
          <Text style={styles.text}>
            Número de raparación: {data.repairNumber}
          </Text>
        </View>
        <View style={styles.line} />
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <Text style={styles.subtitle}>A reparar</Text>
          <View style={styles.column}>
            <View>
              <Text style={styles.text}>
                No enciende: {data.dataRepair.notStart && "X"}
              </Text>
              <Text style={styles.text}>
                No tiene fuerza: {data.dataRepair.notForce && "X"}
              </Text>
              <Text style={styles.text}>
                Arranca y se para: {data.dataRepair.startAndStop && "X"}
              </Text>
            </View>
            <View>
              <Text style={styles.text}>
                Afilación: {data.dataRepair.sharpening && "X"}
              </Text>
              <Text style={styles.text}>
                Carburación: {data.dataRepair.carburation && "X"}
              </Text>
              <Text style={styles.text}>
                Service: {data.dataRepair.service && "X"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.line} />
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <Text style={styles.subtitle}>Notas</Text>
          <Text style={styles.text}>{data.dataRepair.description}</Text>
        </View>

        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <View style={styles.line} />
          <Text style={styles.footer}>
            Una vez cumplidos los 90 días desde la fecha en que se realizó la
            reparación, es importante destacar que si la máquina no es retirada
            en este plazo, perderá automáticamente cualquier posibilidad de
            efectuar reclamos o solicitar garantías adicionales
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      document={<MyDocument />}
      fileName={`${data.dataClient.name}${data.dataClient.lastName}${data.repairNumber}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <button className="w-full flex justify-center bg-orange-500 rounded-full hover:bg-orange-600  text-gray-100 p-3 tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
            Generando PDF...
          </button>
        ) : (
          <button className="w-full flex justify-center bg-orange-500 rounded-full hover:bg-orange-600  text-gray-100 p-3 tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
            Descargar PDF
          </button>
        )
      }
    </PDFDownloadLink>
  );
}

export default PDFNewRepair;
