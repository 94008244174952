import { FaInfoCircle } from "react-icons/fa";

function AlertsMenssages({ type, message }) {
  const alertType = (type) => {
    switch (type) {
      case "danger":
        return "bg-red-100 text-red-700";
      case "success":
        return "bg-green-100 text-green-700";
      case "warning":
        return "bg-yellow-100 text-yellow-700";
      default:
        break;
    }
  };

  const alertTitle = (type) => {
    switch (type) {
      case "danger":
        return "¡Error!";
      case "success":
        return "¡Éxito!";
      case "warning":
        return "¡Advertencia!";
      default:
        break;
    }
  };

  return (
    <div className={`flex ${alertType(type)} rounded-lg p-4 text-sm`}>
      <FaInfoCircle className="w-5 h-5 inline mr-3" />
      <div>
        <span className="font-medium">{alertTitle(type)}</span> {message}
      </div>
    </div>
  );
}

export default AlertsMenssages;
