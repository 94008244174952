import { useState } from "react";
import { useAuthContext } from "../contexts/authContext";
import {
  FaAngleRight,
  FaUser,
  FaTag,
  FaHome,
  FaLandmark,
  FaUsers,
  FaSignInAlt,
  FaHammer,
  FaChartPie,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

function Sidebar({ children }) {
  const [open, setOpen] = useState(true);

  const { logout } = useAuthContext();

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/dashboard");
  };

  const menu = [
    { icon: <FaHome />, title: "Home", route: "/dashboard" },
    {
      icon: <FaUser />,
      title: "Clientes",
      route: "/dashboard/clientes",
      gap: true,
    },
    {
      icon: <FaHammer />,
      title: "Reparaciones",
      route: "/dashboard/reparaciones",
    },

    { icon: <FaTag />, title: "Productos", route: "/dashboard/productos" },
    {
      icon: <FaUsers />,
      title: "Proveedores",
      route: "/dashboard/proveedores",
    },

    {
      icon: <FaChartPie />,
      title: "Caja",
      route: "/dashboard/caja",
      gap: true,
    },
    {
      icon: <FaLandmark />,
      title: "Banco",
      route: "/dashboard/banco",
    },
  ];

  return (
    <div className="flex items-start justify-between">
      <div
        className={` ${
          open ? "w-72" : "w-20"
        } bg-primary h-screen p-5 pt-8 relative duration-300 `}
      >
        <FaAngleRight
          className={`text-primary absolute cursor-pointer -right-3 top-9 w-6 h-6 bg-white ring-black
             border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src="/logoLight.png"
            alt="logo"
            className={`cursor-pointer duration-500 w-14 rounded-full ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            El Emporio
          </h1>
        </div>
        <ul className="pt-6">
          {menu.map((menu, index) => (
            <Link to={menu.route} key={index}>
              <li
                key={index}
                className={`flex rounded-md p-2 cursor-pointer text-white text-sm items-center gap-x-4 hover:bg-[rgba(255,255,255,0.17)]
                ${menu.gap ? "mt-9" : "mt-4"}  `}
              >
                <div className="text-2xl">{menu.icon}</div>
                <span
                  className={`${!open && "hidden"} origin-left duration-200`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
          <div className="absolute bottom-0 my-10">
            <button
              className="flex rounded-md p-2 cursor-pointer text-white text-sm items-center gap-x-4 hover:bg-[rgba(255,255,255,0.17) mb-0"
              onClick={handleLogout}
            >
              <FaSignInAlt className="mr-2 text-xl" />
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                SALIR
              </span>
            </button>
          </div>
        </ul>
      </div>

      <div className="h-screen flex-1 bg-gray-100 dark:bg-dark overflow-hidden">
        <Navbar />
        <div className="w-full h-full overflow-y-auto ">
          <div className="pt-6 pl-10 pr-10 pb-20 relative">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
