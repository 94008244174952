import React, { useEffect, useMemo, useState } from "react";
import { getAllRepairs } from "../../application/repair";
import { getCustomerById } from "../../application/client";
import TableContainer from "../../components/TableContainer";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";

function TableRepair() {
  const [isLoading, setIsLoading] = useState(true);
  const [repairs, setRepairs] = useState([]);
  const [diasEnTaller, setDiasEnTaller] = useState([]);

  console.log(repairs.customerName);

  useEffect(() => {
    const getRepairs = async () => {
      const response = await getAllRepairs();
      const repairsWithCustomerNames = await Promise.all(
        response.map(async (repair) => {
          const customer = await getCustomerById(repair.clientId);
          console.log(customer);
          const fechaIngreso = new Date(repair.dateAdmission);
          const dias =
            repair.repairStatus === "entregada"
              ? 0
              : Math.floor((new Date() - fechaIngreso) / (1000 * 60 * 60 * 24));
          return {
            ...repair,
            customerName: customer.name + " " + customer.lastName,
            customerCompanyName: customer.nameCompany,
            diasEnTaller: dias,
          };
        })
      );
      setRepairs(repairsWithCustomerNames);
      setIsLoading(false);
      setDiasEnTaller(
        repairsWithCustomerNames.map((repair) => repair.diasEnTaller)
      );
    };

    getRepairs();
  }, []);

  console.log(repairs);

  const title = "Lista de Reparaciones";

  function dateFormater(date) {
    const dateFormated = new Date(date).toLocaleDateString("es-ES");
    return dateFormated;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Número de reparacion",
        accessor: "repairNumber",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Fecha de ingreso",
        accessor: "dateAdmission",
        Cell: ({ value }) => (
          <span className="capitalize">{dateFormater(value)}</span>
        ),
      },
      {
        Header: "Fecha de reparación",
        accessor: "repairDate",
        Cell: ({ value }) => (
          <span className="capitalize">
            {value !== "" ? dateFormater(value) : "-"}
          </span>
        ),
      },
      {
        Header: "Fecha de entrega",
        accessor: "deadline",
        Cell: ({ value }) => (
          <span className="capitalize">
            {value !== "" ? dateFormater(value) : "-"}
          </span>
        ),
      },
      {
        Header: "Dias de permanencia",
        accessor: "diasEnTaller",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Marca",
        accessor: "brand",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Modelo",
        accessor: "model",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Cliente",
        accessor: ({ customerName, customerCompanyName }) => {
          if (customerCompanyName) {
            return customerCompanyName;
          } else if (customerName) {
            return customerName;
          } else {
            return "-";
          }
        },
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },

      {
        Header: "Estado",
        accessor: "repairStatus",
        Cell: ({ value }) => (
          <span className="capitalize">
            {value === "pendiente" ? (
              <span className="px-2 py-1 text-base rounded-full bg-yellow-200 text-yellow-600">
                {value}
              </span>
            ) : value === "reparada" ? (
              <span className="px-2 py-1 text-base rounded-full bg-green-200 text-green-600">
                {value}
              </span>
            ) : value === "entregada" ? (
              <span className="px-2 py-1 text-base rounded-full bg-orange-200 text-orange-600">
                {value}
              </span>
            ) : null}
          </span>
        ),
      },
      {
        Header: "Editar",
        accessor: "id",
        Cell: ({ value }) => (
          <div>
            <Link to={`ver/?id=${value}`}>
              <FaPencilAlt className="text-lg text-gray-700 w-full hover:opacity-75" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <Loading />;
  }

  if (repairs.length === 0) {
    return (
      <div className="h-full">
        <div className="container mx-auto">
          <div className="text-center p-8">
            <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
              {title}
            </h1>
          </div>
          <div className="pl-2 pr-2">
            <AlertsMenssages
              className
              type="warning"
              message="No hay registros"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
            {title}
          </h1>
        </div>
        <TableContainer columns={columns} data={repairs} />
      </div>
    </div>
  );
}

export default TableRepair;
