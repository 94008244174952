import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { FaUserAlt } from "react-icons/fa";
import { saveNewCheck } from "../../application/checks";
import { useState } from "react";

function NewCheck() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    saveNewCheck(data);
    alert("Cheque creado con éxito");
    reset();
    setIsSubmitted(true);
  };
  return (
    <section className="w-8/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
      <h1 className="text-xl font-bold text-black capitalize dark:text-white">
        Nuevo Cheque
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mt-4 mb-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">
              Datos del Cheque
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="typeCheck"
            >
              Propios
            </label>
            <input
              {...register("typeCheck")}
              type="radio"
              value="propio"
              required={true}
            />
          </div>

          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="typeCheck"
            >
              Terceros
            </label>
            <input
              {...register("typeCheck")}
              type="radio"
              value="tercero"
              required={true}
            />
          </div>
          <FormField
            label="Fecha de emisión"
            id="dateOfIssue"
            name="fecha de emisión"
            required={true}
            type="date"
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="Fecha de cobro"
            id="paymentDate"
            name="fecha de cobro"
            type="date"
            required={true}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Estado del cheque"
            id="checkStatus"
            name="estado del cheque"
            type="select"
            required={true}
            placeholder="Estado del cheque"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar Estado" },
              { value: "activo", label: "Activo" },
              { value: "anulado", label: "Anulado" },
              { value: "pendiente", label: "Pendiente" },
              { value: "emitido", label: "Emitido" },
            ]}
            //validation={NAME}
          />

          <FormField
            label="Monto $"
            id="amount"
            name="monto"
            type="number"
            required={true}
            maxLength={22}
            placeholder="$ Monto"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>
        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Banco</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <FormField
            label="Nombre del Banco"
            id="bankName"
            name="nombre del banco"
            required={true}
            type="text"
            maxLength={20}
            placeholder="Nombre del Banco"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={DOMICILIO}
          />

          <FormField
            label="Número de cheque"
            id="checkNumber"
            name="número de cheque"
            type="number"
            required={true}
            maxLength={10}
            placeholder="Número de cheque"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Nombre del cliente"
            id="clientName"
            name="nombre del cliente"
            type="text"
            required={true}
            maxLength={20}
            placeholder="Nombre del cliente"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Destinatario"
            id="addressee"
            name="destinatario"
            type="text"
            required={true}
            maxLength={20}
            placeholder="Destinatario"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#EE761B] hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
          >
            Guardar
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewCheck;
