import firebase, { db } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
} from "firebase/firestore";

// Save new client
export const saveNewClient = async (data) => {
  await addDoc(collection(db, "clients"), data);
};

// Get all clients
export const getAllClients = async () => {
  const querySnapshot = await getDocs(collection(db, "clients"));
  const clients = [];
  querySnapshot.forEach((doc) => {
    clients.push({ ...doc.data(), id: doc.id });
  });
  return clients;
};

//get patient by ID
export const getClientID = async (userId) => {
  const docRef = doc(db, "clients", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const getSearchClients = async (searchText) => {
  const querySnapshot = await getDocs(collection(db, "clients"));

  const clients = querySnapshot.docs.map((doc) => {
    const clientData = { ...doc.data(), id: doc.id };
    return clientData;
  });

  const filteredClients = clients.filter((client) => {
    const { name, lastName, cuilCuitDni, nameCompany } = client;
    const fullName = `${name || ""} ${lastName || ""}`;

    return (
      (name && name.includes(searchText.name)) ||
      (lastName && lastName.includes(searchText.name)) ||
      (cuilCuitDni && cuilCuitDni.includes(searchText.name)) ||
      (nameCompany && nameCompany.includes(searchText.name)) ||
      fullName.includes(searchText.name)
    );
  });

  return filteredClients;
};

export const getCustomerById = async (customerId) => {
  try {
    const customerDocRef = doc(db, "clients", customerId);
    const customerSnapshot = await getDoc(customerDocRef);

    if (customerSnapshot.exists()) {
      const customer = { ...customerSnapshot.data(), id: customerId };
      return customer;
    } else {
      throw new Error("Cliente no encontrado");
    }
  } catch (error) {
    // Manejo de errores
    console.error("Error al obtener el cliente por ID:", error);
    throw error;
  }
};
