import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../application/firebase";

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = useCallback(async (data) => {
    await signInWithEmailAndPassword(auth, data.email, data.password);
  }, []);

  const resetPassword = useCallback(async (data) => {
    await sendPasswordResetEmail(auth, data.email);
  }, []);

  const logout = useCallback(() => {
    signOut(auth);
  }, []);

  const signup = useCallback(async (data) => {
    await createUserWithEmailAndPassword(auth, data.email, data.password).then(
      () => {
        updateProfile(auth.currentUser, {
          displayName: `${data.userName} ${data.lastName}`,
        });
        sendEmailVerification(auth.currentUser);
      }
    );
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      signup,
      resetPassword,
      loading,
      user,
    }),
    [login, logout, signup, resetPassword, loading, user]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
