import firebase, { db } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
} from "firebase/firestore";

// Save new provider
export const saveNewProvider = async (data) => {
  await addDoc(collection(db, "providers"), data);
};

// Get all providers
export const getAllProviders = async () => {
  const querySnapshot = await getDocs(collection(db, "providers"));
  const providers = [];
  querySnapshot.forEach((doc) => {
    providers.push({ ...doc.data(), id: doc.id });
  });
  return providers;
};
