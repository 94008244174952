import React from "react";
import ActionCard from "../../components/ActionCard";
import { useNavigate } from "react-router-dom";

import { FaUserPlus, FaUser } from "react-icons/fa";

function Providers() {
  const navigate = useNavigate();

  const cards = [
    {
      description: "Agregar un nuevo proveedor",
      icon: <FaUserPlus />,
      routePath: "/dashboard/proveedores/agregar",
      title: "Agregar proveedor",
    },
    {
      description: "Ver todos los proveedores",
      icon: <FaUser />,
      routePath: "/dashboard/proveedores/ver",
      title: "Ver proveedores",
    },
  ];

  const handleOnClickCard = (routePath) => {
    navigate(routePath);
  };

  return (
    <div className="flex flex-wrap justify-center w-full sm:justify-start ">
      {cards.map((card, index) => (
        <ActionCard
          description={card.description}
          icon={card.icon}
          key={index}
          title={card.title}
          onClick={() => handleOnClickCard(card.routePath)}
        />
      ))}
    </div>
  );
}

export default Providers;
