import React, { useEffect, useMemo, useState } from "react";
import { getAllClients } from "../../application/client";
import TableContainer from "../../components/TableContainer";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

function TableClient() {
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClients = async () => {
      const response = await getAllClients();
      setClients(response);
      setIsLoading(false);
    };
    getClients();
  }, []);

  const title = "Lista de Clientes";

  const columns = useMemo(
    () => [
      {
        Header: "Nombre Cliente",
        accessor: ({ name, lastName, nameCompany }) =>
          nameCompany ? nameCompany : `${name} ${lastName}`,
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "cuit / cuil / dni",
        accessor: "TaxIdentification",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Numero de documento",
        accessor: "cuilCuitDni",
      },
      {
        Header: "Localidad",
        accessor: "location",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Dirección",
        accessor: "direction",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Teléfono",
        accessor: "phone",
      },
      {
        Header: "Ver mas",
        accessor: "id",
        Cell: ({ value }) => (
          <div>
            <Link to={`historial/?id=${value}`}>
              <FaEye className="text-lg text-gray-700 w-full hover:opacity-75" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <Loading />;
  }

  if (clients.length === 0) {
    return (
      <div className="h-full">
        <div className="container mx-auto">
          <div className="text-center p-8">
            <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
              {title}
            </h1>
          </div>
          <div className="pl-2 pr-2">
            <AlertsMenssages
              className
              type="warning"
              message="No hay registros"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
            {title}
          </h1>
        </div>
        <TableContainer columns={columns} data={clients} />
      </div>
    </div>
  );
}

export default TableClient;
