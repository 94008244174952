import { db } from "./firebase"; // Importa el objeto db desde el archivo "firebase.js"
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  orderBy,
  limit,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

//todas las reparaciones
export const getAllRepairs = async () => {
  const querySnapshot = await getDocs(collection(db, "repair"));
  const repairs = [];
  querySnapshot.forEach((doc) => {
    repairs.push({ ...doc.data(), id: doc.id });
  });
  return repairs;
};

//  reparaciones por id de cliente
export const repairClientID = async (userId) => {
  const querySnapshot = await getDocs(
    query(collection(db, "repair"), where("clientId", "==", userId))
  );
  const repairs = [];
  querySnapshot.forEach((doc) => {
    repairs.push({ ...doc.data(), id: doc.id });
  });
  return repairs;
};

//  reparaciones por id de reparación
export const getRepairID = async (repairId) => {
  const docRef = doc(db, "repair", repairId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const saveNewRepair = async (data) => {
  try {
    const highestNumber = await getHighestRepairNumber();
    const repairNumber = (parseInt(highestNumber) + 1)
      .toString()
      .padStart(6, "0");
    const datos = {
      ...data,
      repairNumber: repairNumber,
    };
    await addDoc(collection(db, "repair"), datos);
    return true;
  } catch (error) {
    console.error("Error al guardar la nueva reparación:", error);
    return false;
  }
};

export const getHighestRepairNumber = async () => {
  try {
    const q = query(
      collection(db, "repair"),
      orderBy("repairNumber", "desc"),
      limit(1)
    );
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      // No hay reparaciones existentes, devuelve el número inicial
      return 0;
    }

    const highestNumber = snapshot.docs[0]
      .data()
      .repairNumber.toString()
      .padStart(6, "0");
    return highestNumber;
  } catch (error) {
    console.error("Error al obtener el número de reparación más alto:", error);
    return 0;
  }
};

//total de reparaciones
export const getTotalRepairs = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "repair"));
    return querySnapshot.size;
  } catch (error) {
    console.error("Error al obtener el total de reparaciones:", error);
    return 0;
  }
};

//total de reparaciones pendientes
export const getTotalPendingRepairs = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "repair"), where("repairStatus", "==", "pendiente"))
    );
    return querySnapshot.size;
  } catch (error) {
    console.error("Error al obtener el total de reparaciones:", error);
    return 0;
  }
};

// total de reparaciones en reparadas
export const getTotalRepairedRepairs = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "repair"), where("repairStatus", "==", "reparada"))
    );
    return querySnapshot.size;
  } catch (error) {
    console.error("Error al obtener el total de reparaciones:", error);
    return 0;
  }
};

// total de reparaciones entregadas
export const getTotalDeliveredRepairs = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "repair"), where("repairStatus", "==", "entregada"))
    );
    return querySnapshot.size;
  } catch (error) {
    console.error("Error al obtener el total de reparaciones:", error);
    return 0;
  }
};

//actualizar reparación por id
export const updateRepair = async (repairId, updatedData) => {
  try {
    const repairRef = doc(db, "repair", repairId);
    await updateDoc(repairRef, updatedData);
    return true;
  } catch (error) {
    console.error("Error al actualizar la reparación:", error);
    return false;
  }
};
