const dolarURL = "https://api.bluelytics.com.ar/v2/evolution.json";

export const getDolarBlue = async () => {
  const response = await fetch(dolarURL);
  const data = await response.json();
  return parseInt(data[1].value_buy);
};

export const getDolarOficial = async () => {
  const response = await fetch(dolarURL);
  const data = await response.json();
  return parseInt(data[0].value_buy);
};
