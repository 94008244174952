import { useState, useEffect } from "react";
import { getDolarBlue, getDolarOficial } from "../services/dolar";
import RepairCounter from "../components/RepairCounter";

function Home() {
  const [dolarBlue, setDolarBlue] = useState("cargando...");
  const [dolarOficial, setDolarOficial] = useState("cargando...");

  useEffect(() => {
    getDolarBlue().then((dolarBlue) => {
      setDolarBlue(dolarBlue);
    });

    getDolarOficial().then((dolarOficial) => {
      setDolarOficial(dolarOficial);
    });
  }, []);

  return (
    <>
      <div className="bg-white dark:bg-gray-700 rounded-lg w-full shadow-xl">
        <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span className="block">Bienvenido El Emporio 👋</span>
            <span className="block text-primary">
              Cotización en tiempo real del Dolar Blue: {dolarBlue} - Dolar
              oficial: {dolarOficial}
            </span>
          </h2>
        </div>
        <RepairCounter />
      </div>
    </>
  );
}

export default Home;
