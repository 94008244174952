import { useForm } from "react-hook-form";
import { useAuthContext } from "../contexts/authContext";
import AlertsMenssages from "../components/AlertsMenssages";
import { useState } from "react";
import { FaInstalod } from "react-icons/fa";
import FormField from "../components/FormField";
import { useNavigate } from "react-router-dom";

function Login() {
  const [error, setError] = useState("");
  const { login } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setError("");
    try {
      await login(data);
      navigate("/");
    } catch (error) {
      //console.log(error.code);
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/too-many-requests"
      ) {
        setError(
          "El nombre de usuario o contraseña que ingresaste son incorrectas. Por favor, intenta nuevamente."
        );
      }
      if (error.code === "auth/too-many-requests") {
        setError(
          "Has excedido el número de intentos. Por favor, intenta nuevamente más tarde."
        );
      }
      if (error.code === "auth/wrong-password") {
        setError(
          "La contraseña ingresada es anterior a la actual. Por favor, intenta nuevamente."
        );
      }
      /*if (user.emailVerified === false) {
        setError("Correo no verificado.");
      }*/
      //setError(error.message);
    }
  };
  return (
    <div className="antialiased bg-gradient-to-b from-primary to-white">
      <div className="container px-6 mx-auto">
        <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
          <div className="flex flex-col w-full">
            <img
              src="/logoLight.png"
              alt="logo"
              className="w-20 h-20 mx-auto md:mx-0"
            />
            <h1 className="text-5xl text-white font-bold">Bienvenido</h1>
            <p className="w-5/12 mx-auto md:mx-0 text-white">
              Accede al dashboard de su negocio.
            </p>
          </div>
          <div className="w-full md:w-full lg:w-9/12 mx-auto md:mx-0">
            <div className="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              <h2 className="text-2xl font-bold text-gray-800 text-left mb-5">
                Iniciar Sesión
              </h2>
              {error && <AlertsMenssages type="danger" message={error} />}
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormField
                  id="email"
                  name="email"
                  required={true}
                  type="email"
                  label="Email"
                  maxLength={60}
                  placeholder="Correo electronico"
                  errors={errors}
                  register={register}
                />
                <FormField
                  id="password"
                  name="password"
                  required={true}
                  type="password"
                  label="Contraseña"
                  maxLength={60}
                  placeholder="Contraseña"
                  errors={errors}
                  register={register}
                />
                <div className="flex flex-col w-full my-5">
                  <button
                    type="submit"
                    className="w-full py-4 bg-primary rounded-lg text-orange-100"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <div className="mr-2"></div>
                      <div className="font-bold text-white">INGRESAR</div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
