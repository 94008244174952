import { useState } from "react";
import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { saveNewCash } from "../../application/cash";

function NewCash() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    saveNewCash(data);
    alert("Movimiento agregado con éxito");
    reset();
    setIsSubmitted(true);
  };

  return (
    <section className="w-8/12 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-700">
      <h1 className="text-xl font-bold text-black capitalize dark:text-white">
        Nuevo Movimiento
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mt-4 mb-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">
              Datos del Movimiento
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 mb-4">
          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="cash"
            >
              Ingreso
            </label>
            <input
              {...register("cash")}
              type="radio"
              value="1"
              required={true}
            />
          </div>

          <div className="flex justify-center">
            <label
              className="text-gray-700 dark:text-white mr-2"
              htmlFor="cash"
            >
              Egreso
            </label>
            <input
              {...register("cash")}
              type="radio"
              value="2"
              required={true}
            />
          </div>

          <FormField
            label="Nombre del Movimiento"
            id="name"
            name="nombre"
            type="text"
            required={true}
            maxLength={40}
            placeholder="Nombre"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Fecha del Movimiento"
            id="dateMovement"
            name="fecha del movimiento"
            type="date"
            required={true}
            placeholder="Fecha del Movimiento"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />

          <FormField
            label="Seleccionar tipo de moneda"
            id="typeCurrency"
            required={true}
            name="seleccionar tipo de moneda"
            type="select"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar AR$ / USD" },
              { value: "$", label: "AR$" },
              { value: "USD", label: "USD" },
            ]}
          />

          <FormField
            label="Monto"
            id="amount"
            name="monto"
            type="text"
            required={true}
            placeholder="Monto"
            autoComplete={"off"}
            errors={errors}
            register={register}
            //validation={NAME}
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
          >
            Guardar
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewCash;
