import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";

function PublicRoute() {
  const { user } = useAuthContext();

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

export default PublicRoute;
