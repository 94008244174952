import React, { useEffect, useState } from "react";
import { getRepairID, updateRepair } from "../../application/repair";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";
import FormField from "../../components/FormField";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaCheckCircle, FaBan } from "react-icons/fa";

function ViewRepair() {
  const [isLoading, setIsLoading] = useState(true);
  const [idRepair, setIdRepair] = useState(null);
  const [repair, setRepair] = useState(null);
  const [estadoRepair, setEstadoRepair] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const newRepairStatus = watch("newStatusRepair");

  const onSubmit = (data) => {
    const updateRepairData = {
      repairStatus: data.newStatusRepair,
    };

    if (data.newStatusRepair === "entregada") {
      updateRepairData.deadline = data.deadline;
      updateRepairData.observations = data.observations;
    } else if (data.newStatusRepair === "reparada") {
      updateRepairData.repairDate = data.repairDate;
      updateRepairData.repairNotes = data.repairNotes;
    }

    const success = updateRepair(idRepair, updateRepairData);
    if (success) {
      alert("Se actualizó correctamente");
      fetchRepairData();
    } else {
      alert("No se pudo actualizar");
    }
  };

  const fetchRepairData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setIdRepair(id);

    const repair = await getRepairID(id);
    setEstadoRepair(repair.repairStatus);
    setRepair(repair);
    setIsLoading(false);
  };

  function dateFormater(date) {
    const dateFormated = new Date(date).toLocaleDateString("es-ES");
    return dateFormated;
  }

  useEffect(() => {
    fetchRepairData();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-6">
        <Loading />
      </div>
    );
  }

  if (!repair) {
    return (
      <AlertsMenssages type="danger" message="No hay datos de la reparación" />
    );
  }

  return (
    <div className="container mx-auto">
      <div className="text-center p-8">
        {isLoading && <Loading />}
        {!isLoading && repair && (
          <>
            <h1 className="font-bold text-4xl text-black pb-4 capitalize dark:text-white">
              {repair.brand} {repair.model}
            </h1>
            <div className="container max-w-screen-lg mx-auto text-start">
              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 dark:bg-gray-700">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="text-gray-600 dark:text-white">
                    <p className="font-medium text-lg ">Número de Reparación</p>
                    {repair.repairNumber}
                  </div>

                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-2">
                        <label className="dark:text-white">Marca</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {repair.brand}
                        </p>
                      </div>
                      <div className="md:col-span-2">
                        <label className="dark:text-white">Modelo</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {repair.model}
                        </p>
                      </div>
                      <div className="md:col-span-1">
                        <label className="dark:text-white">Orden</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {repair.repairNumber}
                        </p>
                      </div>

                      <div className="md:col-span-5 mt-2 mb-2 text-gray-600">
                        <p className="font-medium text-lg dark:text-white">
                          A reparar
                        </p>
                      </div>

                      <div className="p-2 md:col-span-3 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.notStart && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            No enciende
                          </span>
                        </div>
                      </div>

                      <div className="p-2 md:col-span-2 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.notForce && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            No tiene fuerza
                          </span>
                        </div>
                      </div>

                      <div className="p-2 md:col-span-2 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.startAndStop && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            Arranca y se para
                          </span>
                        </div>
                      </div>

                      <div className="p-2 md:col-span-3 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.sharpening && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            Afilación
                          </span>
                        </div>
                      </div>

                      <div className="p-2 md:col-span-3 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.carburation && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            Carburación
                          </span>
                        </div>
                      </div>

                      <div className="p-2 md:col-span-2 w-full">
                        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                          {repair.service && (
                            <FaCheckCircle className="text-green-500 mr-2 text-2xl" />
                          )}
                          <span className="title-font font-medium">
                            Service
                          </span>
                        </div>
                      </div>

                      <div className="md:col-span-5 mt-2 mb-2 text-gray-600">
                        <p className="font-medium text-lg dark:text-white">
                          Descripción
                        </p>
                      </div>
                      <div className="md:col-span-5">
                        <label className="dark:text-white">Descripción</label>
                        <p className="h-20 border mt-1 pt-2 rounded px-4 w-full bg-gray-50 capitalize">
                          {repair.description
                            ? repair.description
                            : "No hay descripción"}
                        </p>
                      </div>

                      <div className="md:col-span-5 mt-2 mb-2 text-gray-600 bg-orange-100 p-4">
                        <div className="font-medium text-lg">
                          Estado actual de la maquina :{" "}
                          <span className="capitalize">
                            {repair.repairStatus}
                            {repair.repairStatus === "reparada" && (
                              <span>
                                <h2>
                                  Fecha de la repación:{" "}
                                  {dateFormater(repair.repairDate)}
                                </h2>
                                <h2>
                                  Notas de la reparación:{" "}
                                  {repair.repairNotes
                                    ? repair.repairNotes
                                    : "No hay notas"}
                                </h2>
                              </span>
                            )}
                            {repair.repairStatus === "entregada" && (
                              <span>
                                <h2>
                                  Fecha de la entrega:{" "}
                                  {dateFormater(repair.deadline)}
                                </h2>
                                <h2>
                                  Observaciones de la entrega:{" "}
                                  {repair.observations
                                    ? repair.observations
                                    : "No hay notas"}
                                </h2>
                              </span>
                            )}
                          </span>
                        </div>
                      </div>

                      {estadoRepair !== "entregada" && (
                        <form
                          className="md:col-span-5"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <FormField
                            label="Cambiar estado de la reparación"
                            id="newStatusRepair"
                            name="nuevo estado de la reparación"
                            required={true}
                            type="select"
                            errors={errors}
                            register={register}
                            options={[
                              { value: "", label: "Seleccionar estado" },
                              { value: "pendiente", label: "Pendiente" },
                              { value: "reparada", label: "Reparada" },
                              { value: "entregada", label: "Entregada" },
                            ]}
                          />

                          {estadoRepair !== newRepairStatus &&
                            newRepairStatus !== undefined &&
                            newRepairStatus !== "" &&
                            newRepairStatus === "entregada" && (
                              <>
                                <FormField
                                  label="Fecha de entrega"
                                  id="deadline"
                                  name="fecha de entrega"
                                  type="date"
                                  required={true}
                                  errors={errors}
                                  register={register}
                                  //validation={NAME}
                                />

                                <FormField
                                  label="Observaciones"
                                  id="observations"
                                  name="nombre"
                                  type="textarea"
                                  rows={4}
                                  placeholder="Observaciones"
                                  autoComplete={"off"}
                                  errors={errors}
                                  register={register}
                                  //validation={NAME}
                                />
                              </>
                            )}

                          {estadoRepair !== newRepairStatus &&
                            newRepairStatus !== undefined &&
                            newRepairStatus !== "" &&
                            newRepairStatus === "reparada" && (
                              <>
                                <FormField
                                  label="Fecha de reparación"
                                  id="repairDate"
                                  name="fecha de reparación"
                                  type="date"
                                  required={true}
                                  errors={errors}
                                  register={register}
                                  //validation={NAME}
                                />
                                <FormField
                                  label="Notas"
                                  id="repairNotes"
                                  name="notas"
                                  type="textarea"
                                  rows={4}
                                  placeholder="Notas de la reparación"
                                  autoComplete={"off"}
                                  errors={errors}
                                  register={register}
                                  //validation={NAME}
                                />
                              </>
                            )}

                          {estadoRepair !== newRepairStatus &&
                            newRepairStatus !== undefined &&
                            newRepairStatus !== "" && (
                              <div className="flex justify-end mt-6">
                                <button
                                  type="submit"
                                  className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary hover:opacity-80 rounded-md focus:outline-none focus:bg-gray-600"
                                >
                                  Guardar
                                </button>
                              </div>
                            )}
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ViewRepair;
