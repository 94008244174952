import React from "react";
import {
  FaArrowAltCircleUp,
  FaArrowAltCircleDown,
  FaBalanceScale,
} from "react-icons/fa";

function TotalCard({ icon, title, total, selectedCurrency }) {
  return (
    <div className="bg-black/60 to-white/5 p-6 rounded-lg my-2">
      <div className="flex flex-row space-x-8 items-center">
        <div className="text-4xl text-white">{icon}</div>

        <div>
          <p className="text-blue-300 text-sm font-medium uppercase leading-4">
            {title}
          </p>
          <p className="text-white font-bold text-2xl inline-flex items-center space-x-2">
            <span>
              {selectedCurrency} {total}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

function TableCash({ cash, selectedCurrency }) {
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
  // Calcular el total de ingresos y egresos
  const totalIncome = cash
    .filter(
      (item) => item.cash === "1" && item.typeCurrency === selectedCurrency
    )
    .reduce((total, item) => total + parseFloat(item.amount), 0);

  const totalExpense = cash
    .filter(
      (item) => item.cash === "2" && item.typeCurrency === selectedCurrency
    )
    .reduce((total, item) => total + parseFloat(item.amount), 0);

  // Calcular el total neto
  const netTotal = totalIncome - totalExpense;

  // Filtrar por tipo de moneda
  const currencyType = cash.filter(
    (item) => item.typeCurrency === selectedCurrency
  );

  return (
    <>
      <section className="rounded-lg flex flex-col gap-3 justify-evenly  lg:flex-row">
        <TotalCard
          icon={<FaArrowAltCircleUp />}
          selectedCurrency={selectedCurrency}
          title={"Total Ingresos"}
          total={totalIncome}
        />
        <TotalCard
          icon={<FaArrowAltCircleDown />}
          selectedCurrency={selectedCurrency}
          title={"Total Egresos"}
          total={totalExpense}
        />
        <TotalCard
          icon={<FaBalanceScale />}
          selectedCurrency={selectedCurrency}
          title={"Total Neto"}
          total={netTotal}
        />
      </section>

      <section className="bg-white rounded-lg lg:block hidden">
        <div className="mx-auto grid grid-cols-12">
          <div className="col-span-12 sm:col-span-2 rounded-ss-lg">
            <h2 className="p-2 text-center border-b border-gray-300 text-lg font-bold">
              Fecha
            </h2>
            {currencyType.map((item) => (
              <div
                className="py-2 pl-2 text-center border-b border-gray-100"
                key={item.id}
              >
                {formatDate(item.dateMovement)}
              </div>
            ))}
          </div>
          <div className="col-span-12 sm:col-span-6">
            <h2 className="p-2 text-center border-b border-gray-300 text-lg font-bold">
              Detalles
            </h2>
            {currencyType.map((item) => (
              <div
                className="py-2 pl-2 capitalize border-b border-gray-100"
                key={item.id}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className="col-span-12 sm:col-span-2">
            <h2 className="p-2 text-center border-b border-gray-300 text-lg font-bold">
              Ingreso {selectedCurrency}
            </h2>
            {currencyType.map((item) => (
              <div
                className="py-2 pl-2 text-center border-b border-gray-100"
                key={item.id}
              >
                {item.cash === "1" ? item.amount : "-"}
              </div>
            ))}
            <div className="flex flex-col py-2 text-center">
              <span className="font-bold">Total Ingresos:</span>{" "}
              {selectedCurrency + totalIncome.toFixed(2)}
            </div>
          </div>
          <div className="col-span-12 sm:col-span-2 rounded-tr-lg">
            <h2 className="p-2 text-center border-b border-gray-300 text-lg font-bold">
              Egreso {selectedCurrency}
            </h2>
            {currencyType.map((item) => (
              <div
                className="py-2 pl-2 text-center border-b border-gray-100"
                key={item.id}
              >
                {item.cash === "2" ? item.amount : "-"}
              </div>
            ))}
            <div className="flex flex-col py-2 text-center">
              <span className="font-bold">Total Egresos:</span> {""}
              {selectedCurrency + totalExpense.toFixed(2)}
            </div>
          </div>
        </div>
        <div className="text-end border-t border-gray-300 py-2 pr-2 rounded-b-lg">
          <span className="font-bold">Total Neto:</span> {}
          {netTotal.toFixed(2) > 0 ? (
            <span className="text-green-500">
              {selectedCurrency + netTotal.toFixed(2)}
            </span>
          ) : (
            <span className="text-red-500">
              {selectedCurrency + netTotal.toFixed(2)}
            </span>
          )}
        </div>
      </section>
    </>
  );
}

export default TableCash;
