import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./contexts/authContext";
import NewCheck from "./containers/bank/NewCheck";
import Bank from "./containers/cardContainers/Bank";
import Clients from "./containers/cardContainers/Clients";
import Products from "./containers/cardContainers/Products";
import Providers from "./containers/cardContainers/Providers";
import NewClient from "./containers/clients/NewClient";
import Home from "./containers/Home";
import NewProduct from "./containers/products/NewProduct";
import NewProviders from "./containers/providers/NewProviders";
import TableClient from "./containers/clients/TableClient";
import TableProviders from "./containers/providers/TableProviders";
import TableChecks from "./containers/bank/TableChecks";
import NewRepair from "./containers/repair/NewRepair";
import Login from "./containers/Login";
import PublicRoute from "./roter/PublicRoute";
import PrivateRoute from "./roter/PrivateRoute";
import Repair from "./containers/cardContainers/Repair";
import TableRepair from "./containers/repair/TableRepair";
import ViewClient from "./containers/clients/ViewClient";
import ViewRepair from "./containers/repair/ViewRepair";
import Bills from "./containers/cardContainers/Cash";
import NewBill from "./containers/cash/NewCash";
import ViewCash from "./containers/cash/ViewCash";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>

          <Route path="/dashboard/*" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="clientes" element={<Clients />} />
            <Route path="proveedores" element={<Providers />} />
            <Route path="banco" element={<Bank />} />
            <Route path="productos" element={<Products />} />
            <Route path="reparaciones" element={<Repair />} />
            <Route path="caja" element={<Bills />} />

            <Route path="clientes/agregar" element={<NewClient />} />
            <Route path="clientes/ver" element={<TableClient />} />
            <Route path="clientes/ver/:id" element={<ViewClient />} />

            <Route path="proveedores/agregar" element={<NewProviders />} />
            <Route path="proveedores/ver" element={<TableProviders />} />

            <Route path="reparaciones/agregar" element={<NewRepair />} />
            <Route path="reparaciones/buscar" element={<TableRepair />} />
            <Route path="reparaciones/buscar/:id" element={<ViewRepair />} />

            <Route path="banco/agregar" element={<NewCheck />} />
            <Route path="banco/ver" element={<TableChecks />} />

            <Route path="caja/agregar" element={<NewBill />} />
            <Route path="caja/ver" element={<ViewCash />} />

            <Route path="productos/agregar" element={<NewProduct />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
