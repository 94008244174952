import React from "react";

const styleInput =
  "block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary";

const styleSelect =
  "block w-full px-3 py-2.5 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary";

const styleCheckbox = "form-checkbox h-5 w-5 accent-primary";

function FormField({
  label,
  id,
  name,
  value,
  type,
  placeholder,
  autoComplete,
  minLength,
  maxLength,
  validation,
  errors,
  register,
  defaultValue,
  toggleIcon,
  warning,
  required,
  options,
  onChange,
  rows,
}) {
  const renderField = () => {
    switch (type) {
      case "text":
      case "email":
      case "number":
      case "date":
      case "password":
        return (
          <div>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id]
                  ? "text-red-500 dark:text-red-500"
                  : "text-black dark:text-white"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <input
              id={id}
              name={name}
              type={type}
              value={value}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              autoComplete={autoComplete}
              onChange={onChange}
              className={`${styleInput}
                ${errors[id] ? "border-red-500 border-2" : "border-gray-300"} `}
              {...register(id, {
                required: {
                  value: required,
                },
                minLength: {
                  value: minLength,
                  message: `Minimo ${minLength} caracteres`,
                },
                maxLength: {
                  value: maxLength,
                  message: `Maximo ${maxLength} caracteres`,
                },

                pattern: {
                  value: validation,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> no es valido.</span>
                    </>
                  ),
                },
              })}
            />
            {toggleIcon}
            {errors[id] ? (
              <p className="text-red-500 text-sm mt-2">
                {
                  <>
                    <span className="capitalize">{name}</span>
                    <span> es requerido.</span>
                  </>
                }
              </p>
            ) : (
              <p className="text-gray-400 text-sm mt-2">{warning}</p>
            )}
          </div>
        );

      case "select":
        return (
          <>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id]
                  ? "text-red-500 dark:text-red-500"
                  : "text-black dark:text-white"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <select
              id={id}
              value={value}
              className={`${styleSelect}
              ${errors[id] ? "border-red-500 border-2" : "border-gray-300"} `}
              {...register(id, {
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                },
                validate: (value) => value !== "",
              })}
            >
              {options &&
                options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </>
        );

      case "checkbox":
      case "radio":
        return (
          <div className="flex gap-2 items-center">
            <input
              id={id}
              type={type}
              value={value}
              label={label}
              className={styleCheckbox}
              {...register(id, {
                required: {
                  value: required,
                  message: "Debes aceptar los terminos y condiciones",
                },
              })}
            />
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id]
                  ? "text-red-500 dark:text-red-500"
                  : "text-black dark:text-white"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
          </div>
        );

      case "textarea":
        return (
          <div>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <textarea
              id={id}
              name={name}
              value={value}
              rows={rows}
              placeholder={placeholder}
              className={`${styleInput}
                  ${
                    errors[id] ? "border-red-500 border-2" : "border-gray-300"
                  } `}
              {...register(id, {
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                  maxLength: {
                    value: maxLength,
                    message: `Máximo ${maxLength} caracteres`,
                  },
                },

                pattern: {
                  value: validation,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      {warning ? warning : <span> no es válido.</span>}
                    </>
                  ),
                },
              })}
            />
          </div>
        );

      default:
    }
  };

  return (
    <div className="pt-2">
      {renderField()}
      {errors[id] && (
        <p className="text-red-500 text-sm mt-2">{errors[id].message}</p>
      )}
    </div>
  );
}

export default FormField;
