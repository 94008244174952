import React, { useEffect, useMemo, useState } from "react";
import { getAllChecks } from "../../application/checks";
import TableContainer from "../../components/TableContainer";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";

function TableChecks() {
  const [isLoading, setIsLoading] = useState(true);
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    const getChecks = async () => {
      const response = await getAllChecks();
      setChecks(response);
      setIsLoading(false);
    };
    getChecks();
  }, []);

  function dateFormater(date) {
    const dateFormated = new Date(date).toLocaleDateString("es-ES");
    return dateFormated;
  }

  const title = "Lista de Clientes";

  const columns = useMemo(
    () => [
      {
        Header: "Fecha de emisión",
        accessor: "dateOfIssue",
        Cell: ({ value }) => (
          <span className="capitalize">{dateFormater(value)}</span>
        ),
      },
      {
        Header: "Fecha de cobro",
        accessor: "paymentDate",
        Cell: ({ value }) => (
          <span className="capitalize">{dateFormater(value)}</span>
        ),
      },
      {
        Header: "Banco",
        accessor: "bankName",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Número de cheque",
        accessor: "checkNumber",
      },
      {
        Header: "Tipo de cheque",
        accessor: "typeCheck",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Monto",
        accessor: "amount",
      },
      {
        Header: "Cliente",
        accessor: "clientName",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Destinatario",
        accessor: "addressee",
        Cell: ({ value }) => <span className="capitalize">{value}</span>,
      },
      {
        Header: "Estado",
        accessor: "checkStatus",
        Cell: ({ value }) => (
          <span className="capitalize">
            {value === "activo" ? (
              <span className="px-4 py-1 text-base rounded-full text-green-600 bg-green-200">
                {value}
              </span>
            ) : value === "pendiente" ? (
              <span className="px-4 py-1 text-base rounded-full text-yellow-600 bg-yellow-200">
                {value}
              </span>
            ) : value === "anulado" ? (
              <span className="px-4 py-1 text-base rounded-full text-red-600 bg-red-200">
                {value}
              </span>
            ) : value === "cobrado" ? (
              <span className="px-4 py-1 text-base rounded-full text-blue-600 bg-blue-200">
                {value}
              </span>
            ) : (
              <span className="px-4 py-1 text-base rounded-full">{value}</span>
            )}
          </span>
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <Loading />;
  }

  if (checks.length === 0) {
    return (
      <div className="h-full">
        <div className="container mx-auto">
          <div className="text-center p-8">
            <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
              {title}
            </h1>
          </div>
          <div className="pl-2 pr-2">
            <AlertsMenssages
              className
              type="warning"
              message="No hay registros"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
            {title}
          </h1>
        </div>
        <TableContainer columns={columns} data={checks} />
      </div>
    </div>
  );
}

export default TableChecks;
