// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDts35Naei8-9hvQ2osOXqEYXvEF6_dr1g",
  authDomain: "elemporio-da3b8.firebaseapp.com",
  projectId: "elemporio-da3b8",
  storageBucket: "elemporio-da3b8.appspot.com",
  messagingSenderId: "303809303372",
  appId: "1:303809303372:web:859b74de8bbfbe5d91f5e9",
  measurementId: "G-2M4PTNVEF1",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth(app);
const analytics = getAnalytics(app);
