import firebase, { db } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
} from "firebase/firestore";

// Save new check
export const saveNewCheck = async (data) => {
  await addDoc(collection(db, "checks"), data);
};

// Get all checks
export const getAllChecks = async () => {
  const querySnapshot = await getDocs(collection(db, "checks"));
  const checks = [];
  querySnapshot.forEach((doc) => {
    checks.push({ ...doc.data(), id: doc.id });
  });
  return checks;
};
