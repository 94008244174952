import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";
import Sidebar from "../components/Sidebar";

function PrivateRoute() {
  const { user } = useAuthContext();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <Sidebar>
      <Outlet />
    </Sidebar>
  );
}

export default PrivateRoute;
