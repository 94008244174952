import React, { useEffect, useState } from "react";
import { getClientID } from "../../application/client";
import { repairClientID } from "../../application/repair";
import Loading from "../../components/Loading";
import AlertsMenssages from "../../components/AlertsMenssages";

function ViewClient() {
  const [isLoading, setIsLoading] = useState(true);
  const [idClient, setIdClient] = useState(null);
  const [client, setClient] = useState(null);
  const [clientRepairs, setClientRepairs] = useState([{}]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setIdClient(id);

    const fetchClientData = async () => {
      const client = await getClientID(id);
      setClient(client);
      setIsLoading(false);
    };

    const fetchClientRepairs = async () => {
      const clientRepairs = await repairClientID(id);
      setClientRepairs(clientRepairs);
      setIsLoading(false);
    };

    fetchClientData();
    fetchClientRepairs();
  }, []);

  function dateFormater(date) {
    const dateFormated = new Date(date).toLocaleDateString("es-ES");
    return dateFormated;
  }

  if (isLoading) {
    return (
      <div className="mt-6">
        <Loading />
      </div>
    );
  }

  if (!client) {
    return (
      <AlertsMenssages type="danger" message="No hay datos del paciente" />
    );
  }

  return (
    <div className="container mx-auto">
      <div className="text-center p-8">
        {isLoading && <Loading />}
        {!isLoading && client && (
          <>
            <h1 className="font-bold text-4xl text-black pb-4 capitalize dark:text-white">
              {client.name && client.lastName !== ""
                ? `${client.name} ${client.lastName}`
                : client.nameCompany}
            </h1>
            <div className="container max-w-screen-lg mx-auto text-start">
              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 dark:bg-gray-700">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="text-gray-600 dark:text-white">
                    <p className="font-medium text-lg">Datos Personales</p>
                  </div>

                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                      <div className="md:col-span-3">
                        <label className="dark:text-white">
                          Nombre completo
                        </label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.name && client.lastName !== ""
                            ? `${client.name} ${client.lastName}`
                            : client.nameCompany}
                        </p>
                      </div>
                      <div className="md:col-span-2">
                        <label className="dark:text-white">
                          Tipo de usuario
                        </label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.newClientCompany}
                        </p>
                      </div>

                      <div className="md:col-span-3">
                        <label className="dark:text-white">Email</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.email !== ""
                            ? client.email
                            : "- - - - - - - - -"}
                        </p>
                      </div>

                      <div className="md:col-span-2">
                        <label className="dark:text-white">Telefono</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.phone}
                        </p>
                      </div>

                      <div className="md:col-span-3">
                        <label className="dark:text-white">Direccion</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.direction}
                        </p>
                      </div>

                      <div className="md:col-span-2">
                        <label className="dark:text-white">Localidad</label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.location}
                        </p>
                      </div>

                      <div className="md:col-span-2">
                        <label className="dark:text-white">
                          Cuit / Cuil / Dni
                        </label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.TaxIdentification}
                        </p>
                      </div>

                      <div className="md:col-span-2">
                        <label className="dark:text-white">
                          Número de Cuit / Cuil / Dni
                        </label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                          {client.cuilCuitDni}
                        </p>
                      </div>
                      <div className="md:col-span-3">
                        <label className="dark:text-white">
                          Nombre de facturación
                        </label>
                        <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center">
                          {client.billingName !== ""
                            ? client.billingName
                            : "- - - - - - - - -"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {clientRepairs.length !== 0 && (
              <>
                <h1 className="font-bold text-4xl text-black pb-4 capitalize dark:text-white">
                  Historial de reparaciones
                </h1>
                {clientRepairs.map((repair, index) => (
                  <div
                    className="container max-w-screen-lg mx-auto text-start"
                    key={index}
                  >
                    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 dark:bg-gray-700">
                      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                        <div className="text-gray-600 dark:text-white">
                          <p className="font-medium text-lg ">
                            Datos de la maquina
                          </p>
                          <p>{repair.repairNumber}</p>
                        </div>

                        <div className="lg:col-span-2">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                            <div className="md:col-span-3">
                              <label className="dark:text-white">Marca</label>
                              <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                                {repair.brand}
                              </p>
                            </div>
                            <div className="md:col-span-2">
                              <label className="dark:text-white">Modelo</label>
                              <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                                {repair.model}
                              </p>
                            </div>

                            <div className="md:col-span-3">
                              <label className="dark:text-white">
                                Fecha de ingreso
                              </label>
                              <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center">
                                {dateFormater(repair.dateAdmission)}
                              </p>
                            </div>
                            <div className="md:col-span-2">
                              <label className="dark:text-white">Estado</label>
                              <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                                {repair.repairStatus === "pendiente" ? (
                                  <span className="text-base text-yellow-400">
                                    {repair.repairStatus}
                                  </span>
                                ) : repair.repairStatus === "reparada" ? (
                                  <span className="text-base text-green-400">
                                    {repair.repairStatus}
                                  </span>
                                ) : repair.repairStatus === "entregada" ? (
                                  <span className="text-base text-orange-400">
                                    {repair.repairStatus}
                                  </span>
                                ) : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ViewClient;
