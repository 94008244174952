import React, { useEffect, useState } from "react";
import { getAllCash } from "../../application/cash";
import Loading from "../../components/Loading";
import TableCash from "../../components/TableCash";

function ViewCash() {
  const [isLoading, setIsLoading] = useState(true);
  const [cash, setCash] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("$");

  useEffect(() => {
    const getCash = async () => {
      const response = await getAllCash();
      setCash(
        response.sort(
          (a, b) => new Date(a.dateMovement) - new Date(b.dateMovement)
        )
      );
      setIsLoading(false);
    };
    getCash();
  }, []);

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4 dark:text-white">
            Movimientos de Caja
          </h1>
        </div>
        {isLoading && <Loading />}

        {!isLoading && cash && (
          <>
            <button
              className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary hover:opacity-80 rounded-md focus:outline-none mb-2"
              onClick={() =>
                setSelectedCurrency(selectedCurrency === "$" ? "USD" : "$")
              }
            >
              Cambiar a {selectedCurrency === "$" ? "USD" : "AR$"}
            </button>
            <TableCash cash={cash} selectedCurrency={selectedCurrency} />
          </>
        )}
      </div>
    </div>
  );
}

export default ViewCash;
